// Here you can add other styles
*::-webkit-scrollbar {
    width: 8px;           
}
*::-webkit-scrollbar-track {
background: rgb(237, 237, 237); 
}
*::-webkit-scrollbar-thumb {
    background-color: rgb(57, 57, 57);
    border-radius: 20px;
}

.overflowText1{
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 30%;
}